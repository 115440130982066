.P-official {
    width: 100%;
    background-color: #FFFFFF;
    .P-official_content {
        width: 100%;
        .content_area {
            width: 100%;
            .content_area_img {
                width: 100%;
            }
        }
    }
    .distance {
        margin-top: 30px;
    }
    .mediaDistance {
        margin-top: 130px;
    }
}