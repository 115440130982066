.M-modal {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 990;
    .M-modal_mask {
        width: 100%;
        height: 100%;
        background-color: hsl(0, 0%, 0%);
        opacity: 0.5;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 992;
        animation: mask_show 0.5s linear;
    }
    .M-modal_content {
        width: 100%;
        position: fixed;
        left: 0;
        bottom: 0;
        z-index: 1000;
        animation: modal_bottomShow 0.5s;

        background-color: #fff;
        border-top-right-radius: 6px;
        border-top-left-radius: 6px;
        .top {
            width: 100%;
            padding: 40px;
            position: relative;
            p {
                width: 100%;
                text-align: center;
                font-size: 32px;
                font-family: Mont-Regular;
                color: #2C2C36;
            }
            .closeIcon {
                width: 44px;
                position: absolute;
                top: 40px;
                right: 40px;
            }
        }
        .content {
            width: 100%;
            .scrollArea {
                padding-bottom: 44px;
                background-color: #fff;
                position: relative;
                z-index: 1111;
            }
        }
    }
}