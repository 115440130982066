* {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
}
html {
  cellspacing: 0;
}
html {
  -webkit-text-size-adjust: none;
}
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
input,
button,
textarea,
p,
blockquote,
th,
td,
a,
span,
img {
  margin: 0;
  padding: 0;
  word-break: break-all;
  text-shadow: none;
}
fieldset,
img {
  border: 0;
}
:focus {
  outline: 0 none;
}
address,
caption,
cite,
code,
dfn,
strong,
th,
var,
optgroup {
  font-style: normal;
  font-weight: normal;
}
html,
body {
  list-style: none;
  font-family: 'Microsoft yahei', Arial;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-weight: normal;
}
input,
button,
textarea,
select,
optgroup,
option {
  font-family: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
}
input,
select {
  border: 0;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
}
abbr,
acronym {
  border: 0px;
  font-variant: normal;
}
code,
kbd,
samp,
tt {
  font-size: 100%;
}
input,
button,
textarea,
select {
  *font-size: 100%;
}
body {
  line-height: 1.4;
}
ol,
ul {
  list-style: none;
}
li {
  vertical-align: bottom;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
  text-align: left;
}
caption,
th {
  text-align: left;
}
sup,
sub {
  font-size: 100%;
  vertical-align: baseline;
}
ins {
  text-decoration: none;
}
:link,
:visited {
  text-decoration: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
img {
  border: 0 none;
  vertical-align: middle;
}
a,
img {
  outline: none;
}
mark,
time {
  background: none;
  display: inline;
  font-family: Tahoma, Geneva, sans-serif;
}
.clearfix:after {
  clear: both;
  content: ".";
  display: block;
  height: 0;
  visibility: hidden;
}
.clearfix {
  display: inline-block;
}
* html .clearfix {
  height: 1%;
}
.clearfix {
  display: block;
}
a {
  cursor: pointer;
  outline: none;
  text-decoration: none;
  /* transition:all 0.3s ease 0s; */
}
a:hover {
  text-decoration: none;
  /* transition:all 0.3s ease 0s; */
}
a:focus {
  outline: none;
  -moz-outline: none;
}
.fl {
  float: left;
}
.fr {
  float: right;
}
.hc {
  clear: both;
  font-size: 0;
  height: 0;
  line-height: 0;
  overflow: hidden;
}
.hide {
  display: none;
}
i,
em {
  font-style: normal;
}
/* 去除iPhone中默认的input样式 */
input[type="submit"],
input[type="reset"],
input[type="button"],
input {
  -webkit-appearance: none;
  resize: none;
}
/* 取消链接高亮  */
body,
div,
ul,
li,
ol,
h1,
h2,
h3,
h4,
h5,
h6,
input,
textarea,
select,
p,
dl,
dt,
dd,
a,
img,
button,
form,
table,
th,
tr,
td,
tbody,
article,
aside,
details,
figcaption,
figure,
footer,
header,
menu,
nav,
section {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
/* 设置HTML5元素为块 */
article,
aside,
details,
figcaption,
figure,
footer,
header,
menu,
nav,
section,
img {
  display: block;
}
/* 图片自适应 */
img {
  max-width: 100%;
  height: auto;
  width: auto\9;
  /* ie8 */
  -ms-interpolation-mode: bicubic;
  /*为了照顾ie图片缩放失真*/
}
/* 英文单词自动换行 */
p {
  word-break: keep-all;
  word-wrap: break-word;
  white-space: pre-wrap;
}
/* 省略号 */
.ellipsis p {
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
  white-space: nowrap;
}
@font-face {
  font-family: Mont-Bold;
  src: url(../fonts/Mont-Bold.ttf);
}
@font-face {
  font-family: Mont-Heavy;
  src: url(../fonts/Mont-Heavy.ttf);
}
@font-face {
  font-family: Mont-Regular;
  src: url(../fonts/Mont-Regular.ttf);
}
@keyframes mask_show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.5;
  }
}
@keyframes modal_centerShow {
  0% {
    transform: translate(-50%, -50%) scale(0);
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
  }
}
@keyframes modal_bottomShow {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}
html {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
body {
  width: 100%;
  height: 100%;
  overflow: auto;
  margin: 0 auto;
  font-weight: normal;
  text-shadow: none !important;
}
#root {
  height: 100%;
  background-color: #F5F5F5;
}
