.P-agreement {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;

    padding: 40px;
    padding-bottom: 60px;
    background: #f5f5f5;
    p {
        font-size: 28px;
        margin: 10px 0;
    }
    .title {
        font-size: 38px;
        font-weight: bolder;
        margin-bottom: 16px;
    }
    .subtitle {
        font-size: 32px;
        font-weight: bold;
        margin: 16px 0;
    }
    .sub_subtitle {
        font-size: 28px;
        font-weight: bold;
    }
    .email {
        text-decoration: underline;
        color: #2f5ba7;
        font-weight: bold;
    }
}