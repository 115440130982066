.P-loanDetails {
    width: 100%;
    padding-bottom: 200px;

    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;

    display: flex;
    flex-direction: column; /* 竖向排布 */
    height: 100vh; /* 将容器高度设置为视口的100% */
    .P-loanDetails_other {
        flex: 1;
        width: 100%;
        padding: 0 40px;
        background-color: #2C2C36;
        padding-bottom: 200px;
        .other_area {
            width: 100%;
            padding: 10px 10px 40px;
            background-color: #fff;
            border-radius: 6px;
            .status21 {
                margin-top: 45px;
                width: 100%;
                padding: 0 30px;
                .status21_sms {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding-bottom: 40px;
                    border-bottom: 2px solid #F2F3F5;
                    margin-bottom: 40px;

                    font-size: 32px;
                    font-family: Mont-Heavy;
                    color: #2C2C36;
                    .sms_scroll {
                        width: 90px;
                        height: 40px;
                        background-color: #DDE2E6;
                        border-radius: 6px;
                        position: relative;
                        .scorll_div {
                            width: 32px;
                            height: 32px;
                            position: absolute;
                            top: 4px;
                            right: 4px;
                            background-color: #fff;
                            box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.2), 0px 6px 6px 0px rgba(0,0,0,0.17), 0px 13px 8px 0px rgba(0,0,0,0.1), 0px 23px 9px 0px rgba(0,0,0,0.03), 0px 35px 10px 0px rgba(0,0,0,0);
                            border-radius: 2px;
                        }
                    }
                    .issms {
                        background-color: #007CFB;
                        .scorll_div {
                            left: 4px;
                        }
                    }
                }
                img {
                    width: 100%;
                }
            }
            .status169 {
                width: 100%;
                margin-top: 40px;
                margin-bottom: 12px;

                font-size: 36px;
                font-family: Mont-Regular;
                color: #2C2C36;
                text-align: center;
                .card {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    font-size: 48px;
                    font-family: Mont-Heavy;
                    color: #FC4C4D;
                    img {
                        width: 44px;
                        margin-right: 10px;
                        transform: rotate(30deg);
                    }
                }
            }
        }
    }
    .status21_details {
        margin-top: 40px;
        width: 100%;
        padding: 40px;
        background-color: #fff;
        border-radius: 6px;

        display: flex;
        justify-content: space-between;
        align-items: center;

        font-size: 32px;
        font-family: Mont-Regular;
        color: #2C2C36;
        img {
            width: 32px;
        }
    }
    .status_amount {
        width: calc(100% - 146px);
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        z-index: 11;

        text-align: center;
        font-size: 64px;
        font-family: Mont-Heavy;
        color: #2C2C36;
        img {
            width: 90px;
            margin: 0 auto 20px;
        }
    }

    .P-loanDetails_info {
        flex: 1;
        width: 100%;
        padding: 0 40px 200px;
        background-color: #2C2C36;
        .infoArea {
            width: 100%;
            padding: 10px;
            background-color: #fff;
            border-radius: 6px;
        }
        .detailsArea {
            width: 100%;
            margin-top: 40px;
        }
    }
    .P-loanDetails_button {
        width: 100%;
        background-color: #2C2C36;
        padding: 40px;
        padding-bottom: calc(40px + constant(safe-area-inset-bottom));
        padding-bottom: calc(40px + env(safe-area-inset-bottom));

        position: fixed;
        left: 0;
        bottom: 0;
        z-index: 99;

        display: flex;
        justify-content: center;
        align-items: center;
        .buttonArea {
            flex: 1;
        }
    }
    .failedBtn {
        display: block;
        .buttonArea {
            width: 100%;
            margin: 0;
            margin-bottom: 12px;
        }
    }

    .P-loanDetails_score {
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 999;
        .score_mask {
            width: 100%;
            height: 100%;
            background-color: #000;
            opacity: 0.5;
            position: fixed;
            top: 0;
            left: 0;
            z-index: 1006;
            animation: mask_show 0.5s linear;
        }
        .score_info {
            width: 90%;
            position: fixed;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            background-color: #fff;
            border-radius: 6px;

            z-index: 1111;
            animation: modal_centerShow 0.5s linear;
            .closeIcon {
                width: 44px;
                position: absolute;
                right: 40px;
                top: 40px;
                z-index: 1223;
            }
            .info {
                width: 100%;
                padding: 40px;
                .info_desc {
                    text-align: left;
                    font-size: 36px;
                    font-family: Mont-Regular;
                    color: #2C2C36;
                    margin-bottom: 40px;
                }
                .tips {
                    padding-bottom: 60px;
                    border-bottom: 2px solid #F2F3F5;
                }
                .star {
                    margin-bottom: 80px;
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .starItem {
                        flex: 1;

                        font-size: 32px;
                        font-family: Mont-Regular;
                        text-align: center;
                    }
                    img {
                        width: 94px;
                        margin: 0 auto;
                    }
                    .rotate_star {
                        animation: stars 0.5s;
                    }
                }
            }
            .buttonArea {
                width: 100%;
                padding-top: 1px;
            }
        }
    }

    .ing_details {
        width: 100%;
        li {
            width: 100%;
            padding: 0 40px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 24px;

            font-size: 32px;
            font-family: Mont-Regular;
            color: #B8C0C9;
            .value {
                color: #2C2C36;
            }
        }
    }
}

@keyframes stars {
    0%, 100% {
        transform: rotate(0deg);
    }
    25%, 75% {
        transform: rotate(-10deg);
    }
    50% {
        transform: rotate(10deg);
    }
}