.P-bindCard {
    width: 100%;
    height: 100%;
    padding: 40px 40px 200px;
    background-color: #fff;

    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;

    display: flex;
    flex-direction: column; /* 竖向排布 */
    height: 100vh; /* 将容器高度设置为视口的100% */
    .P-bindCard_inputArea {
      flex: 1;
      width: 100%;
      .inputArea_typeList {
          margin-bottom: 30px;
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          
          .type {
            width: 50%;
            p {
              padding-bottom: 25px;
              font-size: 36px;
              font-family: Mont-Regular;
              color: #B8C0C9;
              text-align: center;
            }
            .chosedType {
              color: #2C2C36;
              position: relative;
              &::after {
                content: "";
                width: 60%;
                height: 4px;
                background-color: #007CFB;
                display: inline-block;
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
              }
            }
          }
      }
      .inputArea_inputList {
          width: 100%;
          .inputList {
            margin-bottom: 40px;
            .inputList_title {
              display: inline-block;
              font-size: 32px;
              font-family: Mont-Regular;
              color: #2C2C36;
              margin-bottom: 20px;
            }
            .inputList_input {
              font-family: Mont-Heavy;
              width: 100%;

              display: flex;
              justify-content: space-between;
              align-items: center;
              position: relative;
              .inputList_input_con, .inputList_input_inputchoosedbg {
                width: 85%;
                padding: 10px 30px;
                position: relative;
                .inputList_input_itemImage, .inputList_input_itemImage_none {
                  position: absolute;
                  top: 50%;
                  transform: translateY(-50%);
                  left: 20px;
                  width: 68px;
                  z-index: 11;
                }
                .inputList_input_itemImage_none {
                  height: 68px;
                  background-color: #DDE2E6;
                  border-radius: 50%;
                }
                input {
                  width: 100%;
                  height: 100px;
                  background: transparent;

                  text-overflow: ellipsis;
                  overflow-x: hidden;
                  white-space: nowrap;
                  
                  font-size: 32px;
                  color: #2C2C36;
                  text-align: center;
                  &::placeholder {
                    width: 100%;
                    color: #DDE2E6;
                  }
                }
                input[disabled] {
                  color: #DDE2E6!important; /* 指定禁用状态下的文字颜色 */
                  -webkit-text-fill-color: #DDE2E6!important; /* iOS中禁用状态下文字颜色 */
                  pointer-events: none; /* 禁止输入 */
                  background-color: transparent; /* 去除背景色 */
                  border: none; /* 去除边框 */
                }
                .input_click {
                  width: 100%;
                  height: 100%;
                  position: absolute;
                  top: 0;
                  left: 0;
                  // background-color: #82E318;
                  background: transparent;
                  color: #DDE2E6!important; /* 指定禁用状态下的文字颜色 */
                  -webkit-text-fill-color: #DDE2E6!important; /* iOS中禁用状态下文字颜色 */
                }
                .input_choose_area {
                  padding-left: 68px;
                }
              }
              .inputList_input_selectIcon {
                width: 22px;
                height: 22px;
                position: absolute;
                right: 30px;
                top: 50%;
                transform: translateY(-50%);
              }
            }
          }
      }
      .inputArea_button {
          width: 100%;
          padding: 40px;
          padding-bottom: calc(40px + constant(safe-area-inset-bottom));
          padding-bottom: calc(40px + env(safe-area-inset-bottom));
          background-color: #fff;

          position: fixed;
          bottom: 0;
          left: 0;
          z-index: 99;
      }
    }
    .cardModal {
      width: 100%;
      min-height: 200px;
      max-height: 700px;
      overflow-y: auto;
      .cardModal_cardItemList {
        width: 100%;
        padding: 30px 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .item_info {
          display: flex;
          justify-content: left;
          align-items: center;
          .item_icon {
            width: 68px;
            margin-right: 30px;
          }
          .normalItemColor {
            font-size: 32px;
            font-family: Mont-Regular;
            color: #B8C0C9;
          }
          .choosedItemColor {
            font-size: 36px;
            font-family: Mont-Heavy;
            color: #2C2C36;
            // p {
            //   padding: 0 5px;
            //   color: #384067;
            //   -webkit-text-stroke: 10px transparent;
            //   background: #fff top left / 100% 100%;
            //   -webkit-background-clip: text;
            // }
          }
        }
        .item_choosed {
          width: 44px;
        }
      }
    }
}