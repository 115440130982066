.M-details {
    width: 100%;
    padding: 40px 40px 16px;
    background-color: #fff;
    border-radius: 6px;
    .details_title {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        padding-bottom: 40px;
        border-bottom: 2px solid #F2F3F5;

        font-size: 32px;
        font-family: Mont-Regular;
        color: #010101;
        img {
            width: 24px;
            transform: rotate(90deg);
        }
    }
    .details_info {
        width: 100%;
        margin-top: 40px;

        font-size: 32px;
        font-family: Mont-Regular;
        .title {
            color: #B8C0C9;
        }
        .desc {
            color: #2C2C36;
        }
        .row_info {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 24px;
        }
        .column_info {
            width: 100%;
            p {
                margin-bottom: 20px;
            }
        }
    }
}