.M-detailsTop {
    width: 100%;
    padding: 30px 40px 40px;
    background-image: url(../../common/images/global/area_bg.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;

    font-size: 28px;
    font-family: Mont-Regular;
    color: #2C2C36;
    text-align: left;
    .title {
        font-size: 36px;
        text-align: center;
    }
    .center {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 60px;
        position: relative;
        .left_icon, .right_icon {
            width: 146px;
            height: 236px;
        }
    }
    .tips {
        margin-bottom: 20px;
    }
}