.M-button {
    width: 100%;
    .M-button_content {
        width: 100%;
        background-color: #007CFB;
        border-radius: 6px;
        line-height: normal;
        padding: 34px 0;

        display: flex;
        justify-content: center;
        align-items: center;

        text-align: center;
        color: #fff;
        p {
            font-size: 36px;
            font-family: Mont-Heavy;
            // padding: 5px;
            // letter-spacing: 2px;
            // -webkit-text-stroke: 10px transparent;
            // background: #571B00 top left / 100% 100%;
            // -webkit-background-clip: text;
        }

        .btnIcon {
            width: 34px;
            margin-right: 16px;
        }
    }
}