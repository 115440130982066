.M-card_typeList {
    width: 100%;
    padding: 0 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .cardType {
        font-size: 32px;
        font-family: Mont-Regular;
        color: #B8C0C9;
    }
    .choosedCardType {
        font-size: 36px;
        font-family: Mont-Heavy;
        color: #2C2C36;
        padding-bottom: 10px;
        position: relative;
        &::after {
            content: "";
            display: inline-block;
            width: 80%;
            height: 8px;
            background-color: #FBC200;
            position: absolute;
            left: 50%;
            bottom: 0;
            transform: translateX(-50%);
        }
        // p {
        //     padding: 0 5px;
        //     color: #fff;
        //     -webkit-text-stroke: 10px transparent;
        //     background: #21202E top left / 100% 100%;
        //     -webkit-background-clip: text;
        // }
    }
}
.M-card_list {
    width: 100%;
    padding: 20px 40px 200px;
    height: 500px;
    overflow-y: auto;
    li {
        width: 100%;
        padding: 40px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;

        border-bottom: 4px solid #DDE2E6;
        .list_info {
            display: flex;
            justify-content: left;
            align-items: center;

            font-size: 32px;
            font-family: Mont-Regular;
            color: #B8C0C9;
            img {
                width: 60px;
                margin-right: 30px;
                border-radius: 50%;
            }
            .checkedItem {
                font-size: 32px;
                font-family: Mont-Heavy;
                color: #2C2C36;
                // p {
                //     padding: 0 5px;
                //     color: #fff;
                //     -webkit-text-stroke: 10px transparent;
                //     background: #21202E top left / 100% 100%;
                //     -webkit-background-clip: text;
                // }
            }
        }
        .checkedImg {
            width: 44px;
        }
        &:last-child {
            border: none;
        }
    }
}
.M-card_none {
    width: 100%;
    height: 500px;
    padding-top: 80px;

    font-size: 28px;
    font-family: Mont-Heavy;
    color: #B8C0C9;
    text-align: center;
    img {
        width: 200px;
        margin: 0 auto 10px;
    }
}
.M-card_btn {   
    width: 100%;
    padding: 40px;
    padding-bottom: calc(40px + constant(safe-area-inset-bottom));
    padding-bottom: calc(40px + env(safe-area-inset-bottom));
    position: absolute;
    left: 0;
    bottom: 0;
}