.f-banner-wrap {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    .bApp_top {
        padding-top: 4.4rem;
        // background-color: #FFF2D7;
    }
    img {
        width: 100%;
    }
}
.bpaddingTop {
    padding-top: 2.4rem;
    // background-color: #FFF2D7;
}