.P-confirmOfLoan {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column; /* 竖向排布 */
    height: 100vh; /* 将容器高度设置为视口的100% */

    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    // 真产品
    .P-confirmOfLoan_realArea {
        flex: 1;
        width: 100%;
        padding: 25px 0 200px;
        background-color: #fff;
    }
    .realArea_tips {
        width: 100%;
        padding: 20px 60px;
        background-color: #F2F6FF;
        margin-bottom: 40px;
        
        font-size: 24px;
        font-family: Mont-Regular;
        color: #007CFB;
    }
    .P-confirmOfLoan_amount {
        width: 100%;
        padding: 0 60px;
        .title {
            font-size: 32px;
            font-family: Mont-Regular;
            color: #2C2C36;
            margin-bottom: 20px;
        }
        .amount {
            font-size: 100px;
            text-align: center;
            font-family: Mont-Heavy;

            color: #2C2C36;
            // letter-spacing: 6px;
            // -webkit-text-stroke: 18px transparent;
            // background: #560000 top left / 100% 100%;
            // -webkit-background-clip: text;

            // text-shadow: -10px 16px 0px rgba(0,0,0,0.25);
            position: relative;
            // padding-bottom: 22px;
            border-bottom: 1px solid #F2F3F5;
            span {
                font-size: 40px;
                display: inline-block;
                position: absolute;
                left: 0;
                bottom: 22px;
            }
        }
    }
    .P-confirmOfLoan_infoArea {
        flex: 1;
        width: 100%;
        padding: 60px 60px 0;
        .infoArea_bank { 
            width: 100%;
            position: relative;
            .name {
                font-size: 32px;
                font-family: Mont-Regular;
                color: #2C2C36;
                margin-bottom: 40px;
            }
            .bank_info {
                width: 100%;
                padding: 35px 42px;
                border: 5px solid #007CFB;
                border-radius: 6px;
                position: relative;
                .card {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .card_num {
                        flex: 1;

                        font-size: 36px;
                        font-family: Mont-Heavy;
                        color: #2C2C36;
                        text-align: center;
                    }
                    .confirm_goBank {
                        width: 22px;
                    }
                }
                .error_tipsIcon {
                    width: 50px;
                    position: absolute;
                    top: 50%;
                    left: -25px;
                    transform: translateY(-50%);
                }
            }
            .bank_error {
                width: 100%;
                margin-top: 10px;
                padding: 20px;
                background-color: #FDF6EA;
                border-radius: 6px;
                .tips {
                    font-size: 26px;
                    font-family: Mont-Bold;
                    text-align: center;
                    color: #DD783E;
                    // padding: 0 5px;
                    // letter-spacing: 2px;
                    // -webkit-text-stroke: 10px transparent;
                    // background: #571B00 top left / 100% 100%;
                    // -webkit-background-clip: text;

                    // transform: rotate(-3deg);
                }
            }
        }
        .infoArea_details {
            width: 100%;
            margin-top: 60px;
            .title {
                font-size: 32px;
                font-family: Mont-Regular;
                color: #2C2C36;
                margin-bottom: 16px;
            }
            li {
                width: 100%;
                padding: 24px 0;
                border-bottom: 1px solid #F2F3F5;
                display: flex;
                justify-content: space-between;
                align-items: center;

                font-size: 32px;
                font-family: Mont-Heavy;
                color: #2C2C36;
                .details_left {
                    color: #B8C0C9;
                }
                &:last-child {
                    border: none;
                }
            }
        }
        .infoArea_repay {
            width: 100%;
            margin-top: 16px;
            .title {
                font-size: 32px;
                font-family: Mont-Regular;
                color: #2C2C36;
                margin-bottom: 40px;
            }
            .repay_info {
                width: 100%;
                li {
                    margin-bottom: 30px;
                    width: 100%;
                    position: relative;

                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .index {
                        display: flex;
                        justify-content: center;
                        align-items: baseline;

                        padding: 20px;
                        background-color: #B8C0C9;
                        border-radius: 50%;

                        position: absolute;
                        top: -20px;
                        right: -20px;
                        z-index: 11;
                        .index_num {
                            font-size: 36px;
                            font-family: Mont-Heavy;

                            color: #fff;
                            // padding: 0 6px;
                            // letter-spacing: 0px;
                            // -webkit-text-stroke: 12px transparent;
                            // background: #560000 top left / 100% 100%;
                            // -webkit-background-clip: text;
                        }
                        .index_th {
                            font-size: 36px;
                            font-family: Mont-Heavy;

                            color: #fff;
                            // padding: 0 6px;
                            // letter-spacing: 0px;
                            // -webkit-text-stroke: 12px transparent;
                            // background: #560000 top left / 100% 100%;
                            // -webkit-background-clip: text;
                        }
                    }
                    .info {
                        font-size: 36px;
                        font-family: Mont-Heavy;
                        color: #2C2C36;

                        background-color: #fff;
                        padding: 36px 60px;
                        border: 5px solid #B8C0C9;
                        position: relative;
                        z-index: 1;

                        display: flex;
                        justify-content: space-between;
                        .info_amount {
                            text-align: left;
                        }
                        .info_date {
                            text-align: right;
                        }
                        .introduce {
                            font-size: 28px;
                            font-family: Mont-Regular;
                            color: #B8C0C9;
                        }
                    }
                    &:nth-child(1) {
                        margin-bottom: 180px;
                        .index {
                            background-color: #007CFB;
                        }
                        .info {
                            border: 5px solid #007CFB;
                        }
                    }
                }
            }
        }
        .infoArea_tips {
            position: absolute;
            left: -65px;
            top: 50%;

            width: calc(100% + 130px);
            background-color: #F2F6FF;
            padding: 115px 60px 30px;
            .tips {
                font-size: 28px;
                font-family: Mont-Regular;
                color: #007CFB;
                text-align: center;
            }
        }
    }
    .P-confirmOfLoan_bottom {
        width: 100%;
        padding: 40px;
        padding-bottom: calc(40px + constant(safe-area-inset-bottom));
        padding-bottom: calc(40px + env(safe-area-inset-bottom));

        position: fixed;
        left: 0;
        bottom: 0;
        z-index: 99;
        .bottom_agreement {
            margin-bottom: 40px;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            .agreement_checkedStatus {
                width: 40px;
                margin-right: 24px;
            }
            p {
                font-size: 24px;
                font-family: Mont-Bold;

                color: #B8C0C9;
                // padding: 0 5px;
                // letter-spacing: 2px;
                // -webkit-text-stroke: 10px transparent;
                // background: #571B00 top left / 100% 100%;
                // -webkit-background-clip: text;
            }
            .loanAgreement {
                color: #fff;
                // text-decoration: underline;
            }
        }
        .bottom_btnArea {
            width: 100%;
            margin: 0 auto;
        }
    }
    .p-confirmOfLoan_errorBank {
        margin-top: 60px;
        width: 100%;
        padding-top: 30px;
        border-top: 5px solid #F2F3F5;
        position: relative;
        .errorBank_info {
            width: 100%;
            text-align: center;
            .bankName {
                font-size: 36px;
                font-family: Mont-Regular;
                color: #2C2C36;
            }
            .bankNum {
                margin-top: 16px;
                width: 100%;
                font-size: 48px;
                font-family: Mont-Heavy;
                color: #FC4C4D;
            }
        }
        .errorBank_icon {
            width: 44px;
            margin: 24px auto 0;
            transform: rotate(30deg);
        }
    }

    // 假产品
    .P-confirmOfLoan_shelfArea {
        flex: 1;
        width: 100%;
        background-color: #2C2C36;
        padding: 0 40px 200px;
        .shelfArea_choseArea {
            padding: 10px 10px 60px;
            background-color: #fff;
            border-radius: 6px;
            position: relative;
            .shelfArea_choseArea_scrollAmount {
                width: 100%;
                .scrollAmount {
                    margin-bottom: 30px;
                    width: 100%;

                    font-size: 36px;
                    font-family: Fredoka_Bold;
                    color: #943800;

                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .scrollAmount_scroll {
                        width: 100%;
                        height: 20px;
                        margin: 0 20px;
                        position: relative;

                        // background-image: url(../../common/images/confirmOfLoan/shelf_chosedBg.png);
                        // background-size: 100% 100%;
                        // background-repeat: no-repeat;
                        .shelf_chosedIcon {
                            width: 120px;
                            position: absolute;
                            z-index: 12;
                            top: -18px;
                            left: 0%;
                            transform: translateX(-50%);
                        }
                    }
                }
                .scrollAmount_tips {
                    font-size: 24px;
                    font-family: Fredoka_SemiBold;
                    color: #943800;
                }
            }
            .top_icon {
                position: absolute;
                top: -10px;
                left: -20px;
                width: 124px;
            }
            .title {
                font-size: 36px;
                font-family: Mont-Regular;
                color: #2C2C36;
                text-align: center;
                margin-bottom: 30px;
            }
            .title_left {
                text-align: left;
                margin-bottom: 40px;
            }
            .shelfArea_choseArea_changeAmount {
                width: 100%;
                padding: 30px 40px 44px;
                background-image: url(../../common/images/global/area_bg.png);
                background-size: 100% 100%;
                background-repeat: no-repeat;
                .amount_area {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 20px;
                    position: relative;
                    .left_icon, .right_icon {
                        width: 146px;
                        height: 236px;
                    }
                    .amount {
                        width: calc(100% - 146px);
                        position: absolute;
                        left: 50%;
                        transform: translateX(-50%);
                        z-index: 11;

                        text-align: center;
                        font-size: 90px;
                        font-family: Mont-Heavy;
                        color: #007CFB;
                        .amount_tips {
                            font-size: 32px;
                            color: #B8C0C9;
                        }
                    }
                }
                .tips {
                    font-size: 24px;
                    font-family: Mont-Regular;
                    color: #007CFB;
                    text-align: center;
                    margin-bottom: 20px;
                }
                .change_area {
                    width: 100%; 
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    img {
                        width: 48%;
                    }
                }
            }
            .shelfArea_choseArea_changeTerm {
                margin-top: 50px;
                width: 100%;
                padding: 0 30px;
                .change_term, .date_tips, .date_time, .amount {
                    width: 100%;
                    padding: 35px 50px;
                    border: 5px solid #007CFB;
                    border-radius: 6px;
                    
                    font-size: 36px;
                    font-family: Mont-Heavy;
                    color: #2C2C36;
                    text-align: center;
                }
                .change_term {
                    margin-bottom: 30px;
                    position: relative;
                    .arrow_icon {
                        width: 22px;
                        position: absolute;
                        right: 50px;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                }
                .date {
                    margin-bottom: 30px;
                    .date_tips {
                        border-bottom-left-radius: 0;
                        border-bottom-right-radius: 0;
                    }
                    .date_time {
                        padding: 30px 0;
                        background-color: #007CFB;
                        border-top-right-radius: 0;
                        border-top-left-radius: 0;

                        color: #fff;
                        font-size: 28px;
                        font-family: Mont-Bold;
                        text-align: center;
                    }
                }
                .amount_tips {
                    font-size: 28px;
                    font-family: Mont-Regular;
                    color: #B8C0C9;
                    margin-top: 12px;
                }
            }
            .shelfArea_choseArea_bank {
                margin-top: 60px;
                width: 100%;
                padding: 0 30px;
                .card {
                    width: 100%;
                    padding: 36px 50px;
                    border: 5px solid #007CFB;
                    border-radius: 6px;
                    .card_num {
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        p {
                            font-size: 36px;
                            font-family: Mont-Heavy;
                            color: #2C2C36;
                            text-align: center;
                            width: 100%;
                        }
                        .confirm_goBank {
                            width: 32px;
                        }
                    }
                    .card_name {
                        font-size: 28px;
                        font-family: Mont-Regular;
                        color: #2C2C36;
                        text-align: center;
                        margin-top: 12px;
                    }
                }
            }
            .shelfArea_choseArea_authority {
                margin-top: 60px;
                width: 100%;
                padding: 0 30px;
                img {
                    width: 100%;
                }
            }
        }
    }

    // 期数选择框
    .term_picker {
        width: 100%;
        li {
            width: 100%;
            padding: 30px;
            text-align: center;
            font-size: 36px;
            font-family: Mont-Heavy;
            color: #B8C0C9;
        }
    }
}