/* 英文单词自动换行 */
p {
    word-break: keep-all;
    word-wrap: break-word;
    white-space: pre-wrap; // 连续的空白符会被保留。在遇到换行符或 <br> 元素时，或者根据填充行框盒子的需要换行。
}
/* 省略号 */
.ellipsis {
    p {
      text-overflow: ellipsis;
      overflow: hidden;
      word-break: break-all;
      white-space: nowrap;
    }
}

@font-face {
    font-family: Mont-Bold;
    src: url(../fonts/Mont-Bold.ttf);
}
@font-face {
    font-family: Mont-Heavy;
    src: url(../fonts/Mont-Heavy.ttf);
}
@font-face {
    font-family: Mont-Regular;
    src: url(../fonts/Mont-Regular.ttf);
}

@keyframes mask_show {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 0.5;
    }
}
@keyframes modal_centerShow {
    0% {
        transform: translate(-50%, -50%) scale(0);
    }
    100% {
        transform: translate(-50%, -50%) scale(1);
    }
}
@keyframes modal_bottomShow {
    0% {
        transform: translateY(100%);
    }
    100% {
        transform: translateY(0);
    }
}