.P-code {
    width: 100%;
    height: 100%;
    padding: 25px 40px 40px;
    background-color: #F5F5F5;

    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;

    display: flex;
    flex-direction: column; /* 竖向排布 */
    height: 100vh; /* 将容器高度设置为视口的100% */
    .P-code_info {
        position: relative;
        width: 100%;
        padding: 10px;
        padding-bottom: 40px;
        background-color: #fff;
        border-radius: 6px;

        text-align: center;
        .title {
            font-size: 32px;
            font-family: Mont-Regular;
            color: #2C2C36;
            text-align: center;
        }
        .amount {
            font-size: 64px;
            font-family: Mont-Heavy;
            color: #2C2C36;
            text-align: center;
        }
        .paymentCode {
            font-size: 48px;
            font-family: Mont-Heavy;
            color: #007CFB;
            margin: 30px 0 10px;
        }
        .countdown {
            font-size: 28px;
            font-family: Mont-Regular;
            color: #2C2C36;
            margin-bottom: 30px;
            span {
                color: #007CFB;
            }
        }
        .copy {
            width: calc(100% - 60px);
            height: 80px;
            margin: 0 auto;
        }
    }
    .P-code_code {
        margin-top: 40px;
        width: 100%;
        padding: 40px;
        background-color: #fff;
        border-radius: 6px;
        .info {
            width: 100%;
            li {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 15px;

                font-size: 32px;
                font-family: Mont-Regular;
                color: #B8C0C9;
                .right {
                    color: #2C2C36;
                    text-align: right;
                }
            }
        }
        .barcodeArea {
            width: 100%;
            margin: 25px auto 0;
            .barCode {
                width: 100%;
            }
        }
    }
    .step {
        width: 100%;
        margin-top: 40px;
        .stepItem {
            margin-top: 30px;
            width: 100%;
            font-size: 32px;
            font-family: Mont-Regular;
            color: #B8C0C9;
        }
    }
}