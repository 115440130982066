.P-customerService {
    width: 100%;
    height: 100%;
    padding: 85px 40px 0;
    background-color: #F5F5F5;

    overflow: auto;
    overflow-x: hidden;

    display: flex;
    flex-direction: column; /* 竖向排布 */
    height: 100vh; /* 将容器高度设置为视口的100% */
    .P-customerService_tips {
        width: 100%;
        padding: 30px 40px;
        background-color: #FDF6EA;
        p {
            text-align: center;
            font-size: 28px;
            font-family: Mont-Bold;

            color: #DD783E;
            // letter-spacing: 1px;
            // -webkit-text-stroke: 9px transparent;
            // background: #3E1B1B top left / 100% 100%;
            // -webkit-background-clip: text;
        }
    }
    .P-customerService_content {
        flex: 1;
        width: 100%;
        padding-top: 40px;
        position: relative;
        z-index: 1;
        .P-customerService_content_list {
            width: 100%;
            .P-customerService_content_list_li {
                width: 100%;
                background-color: #fff;
                border-radius: 6px;
                padding: 40px;
                margin-bottom: 40px;

                display: flex;
                justify-content: space-between;
                align-items: center;
                .list_con_right {
                    width: 100%;
                    text-align: left;
                    .list_con_p {
                        width: 100%;
                        font-family: Mont-Regular;
                        font-size: 32px;
                        color: #B8C0C9;
                        .list_con_p_top {
                            font-family: Mont-Regular;
                            font-size: 32px;
                            margin-bottom: 20px;

                            color: #2C2C36;
                            // letter-spacing: 1px;
                            // -webkit-text-stroke: 9px transparent;
                            // background: #3E1B1B top left / 100% 100%;
                            // -webkit-background-clip: text;
                        }
                    }
                }
                .list_con_left {
                    width: 44px;
                    height: 44px;
                }
            }
        }
    }
}