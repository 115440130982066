html {
    width: 100%;
    height: 100%;
    overflow: hidden;
}
body { 
    width: 100%;
    height: 100%;
    overflow: auto;
    margin: 0 auto; 
    font-weight: normal; 
    text-shadow: none !important;
}
#root {
    height: 100%;
    background-color: #F5F5F5;
}