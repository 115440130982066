.M-errorModal_mask {
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.5;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 998;
    animation: mask_show 0.5s linear;
}
.M-errorModal_info {
    width: 90%;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
    animation: modal_centerShow 0.5s linear;
    .closeIcon {
        width: 44px;
        position: absolute;
        right: 40px;
        top: 40px;
        z-index: 1223;
    }
    .infoArea {
        width: 100%;
        // min-height: 700px;
        max-height: 700px;
        padding: 10px;
        background-color: #fff;
        border-radius: 6px;
        text-align: left;
        .infoArea_bg {
            width: 100%;
            padding: 30px 50px 60px;
            background-image: url(../../common/images/global/area_bg.png);
            background-size: 100% 100%;
            background-repeat: no-repeat;
            overflow-y: auto;
        }
        .infoArea_loanFailedTitle {
            font-size: 36px;
            font-family: Mont-Heavy;

            color: #2C2C36;
            // letter-spacing: 0;
            // -webkit-text-stroke: 14px transparent;
            // background: #FFEABF top left / 100% 100%;
            // -webkit-background-clip: text;
        }
        .infoArea_loanFailedTxt {
            min-height: 220px;
            margin-top: 40px;
            font-size: 32px;
            font-family: Mont-Regular;
            color: #2C2C36;
        }
    }
    .infoArea_btn {
        width: 100%;
        margin: 30px auto 0;
    }
    .btnArea {
        margin-top: 30px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .btnArea_btn {
            width: 48%;
        }
    }
}
.closeSyncBtn {
   width: 100%;
   margin: 0 auto; 
   margin-top: 30px;
}