.M-header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100%;
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #F7F7F7;
    .M-header_navLogo {
        width: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        .logo {
            width: 80px;
        }
        .media_logo {
            width: 240px;
            padding: 20px 0;
        }
    }
    .M-header_navgation {
        display: flex;
        justify-content: space-between;
        align-items: center;
        li {
            cursor: pointer;
            font-size: 7px;
            color: #8B3400;
            font-family: Fredoka_Bold;
            padding: 0;
            text-align: center;
            margin: 0 30px;
        }
        .slider {
            font-family: Fredoka_Bold;
            font-size: 7px;
            color: #FFE03A;
            position: relative;
            p {
                padding: 0 2px;
                -webkit-text-stroke: 1.5px transparent;
                background: #560000 top left / 100% 100%;
                -webkit-background-clip: text;
            }
        }
    }
    .M-header_mediaIcon {
        width: 100px;
        margin-right: -30px;
    }
    .M-header_mediaNavgation {
        position: absolute;
        right: 0;
        top: 130px;
        background-color: #FFFFFF;
        padding: 20px;
        max-height: 1000px;
        max-width: 600px;
        animation: show_nav 0.4s;
        li {
            font-size: 36px;
            padding: 30px;
            border-bottom: 1px solid #080804;
            font-family: PlusJakartaSans-SemiBold;
            &:last-child {
                border: none;
            }
        }
        .slider {
            background-color: #8B3400;
            color: #FFFFFF;
        }
    }
    .M-header_mediaNavgation::before {
        content: "";
        display: block;
        border: 20px solid transparent;
        border-bottom: 20px solid #FFFFFF;
        position: absolute;
        top: -38px;
        right: 80px;
    }
}

@keyframes show_nav {
    0% {
        transform: translateX(100%);
    }
    90% {
        transform: translateX(-5%);
    }
    95% {
        transform: translateX(5%);
    }
    100% {
        transform: translateX(0);
    }
}