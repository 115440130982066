.jointLogonFail-wrap {
  width: 100%;
  height: 100vh;
  background-color: #2C2C36;
  padding: 0 40px 160px;

  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  .jointLogonFail-wrap_content {
    width: 100%;
    padding: 10px 10px 50px;
    background-color: #fff;
    border-radius: 6px;
    .fail_icon {
      width: 152px;
      height: 152px;
      img {
        width: 100%;
      }
    }
    .content_tips {
      font-size: 28px;
      color: #010101;
      font-family: Mont-Regular;
      text-align: center;
      margin-top: 50px;
    }
    .tips_bold {
      font-size: 36px;
      color: #007CFB;
      font-family: Mont-Heavy;
      text-align: center;
      margin-top: 10px;
    }
  }
  .jointLogonFail-wrap_btnArea {
    width: 100%;
    padding: 40px 40px 0;
    background-color: #2C2C36;
    padding-bottom: calc(40px + constant(safe-area-inset-bottom));
    padding-bottom: calc(40px + env(safe-area-inset-bottom));

    position: fixed;
    bottom: 0;
    left: 0;
  }
}