.P-repayment {
    width: 100%;
    height: 100%;
    padding: 65px 40px 180px;
    background-color: #F5F5F5;

    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;

    display: flex;
    flex-direction: column; /* 竖向排布 */
    height: 100vh; /* 将容器高度设置为视口的100% */
    .P-repayment_planInfo {
        flex: 1;
        width: 100%;
        .repay_info {
            width: 100%;
            li {
                margin-bottom: 30px;
                width: 100%;
                .infoArea {
                    position: relative;
                    width: 100%;
                }
                .index {
                    display: flex;
                    justify-content: center;
                    align-items: baseline;

                    padding: 20px;
                    background-color: #B8C0C9;
                    border-radius: 50%;

                    position: absolute;
                    top: -20px;
                    right: -20px;
                    z-index: 11;
                    .index_num {
                        font-size: 36px;
                        font-family: Mont-Heavy;

                        color: #fff;
                        // padding: 0 6px;
                        // letter-spacing: 0px;
                        // -webkit-text-stroke: 12px transparent;
                        // background: #560000 top left / 100% 100%;
                        // -webkit-background-clip: text;
                    }
                    .index_th {
                        font-size: 36px;
                        font-family: Mont-Heavy;

                        color: #fff;
                        // padding: 0 6px;
                        // letter-spacing: 0px;
                        // -webkit-text-stroke: 12px transparent;
                        // background: #560000 top left / 100% 100%;
                        // -webkit-background-clip: text;
                    }
                }
                .info {
                    font-size: 36px;
                    font-family: Mont-Heavy;
                    color: #2C2C36;

                    background-color: #fff;
                    padding: 36px 60px;
                    border: 5px solid #B8C0C9;
                    position: relative;
                    z-index: 1;

                    display: flex;
                    justify-content: space-between;
                    .info_amount {
                        text-align: left;
                    }
                    .info_date {
                        text-align: right;
                    }
                    .introduce {
                        font-size: 28px;
                        font-family: Mont-Regular;
                        color: #B8C0C9;
                    }
                }
                &:nth-child(1) {
                    .index {
                        background-color: #007CFB;
                    }
                    .info {
                        border: 5px solid #007CFB;
                    }
                }
            }
        }
        .tips {
            width: 100%;
            padding: 30px 0;
            background-color: #007CFB;
            font-size: 28px;
            font-family: Mont-Heavy;
            color: #fff;
            text-align: center;
        }
    }
    .P-repayment_delayInfo {
        flex: 1;
        position: relative;
        width: 100%;
    }
    .P-repayment_btnArea {
        width: 100%;
        padding: 40px;
        padding-bottom: calc(40px + constant(safe-area-inset-bottom));
        padding-bottom: calc(40px + env(safe-area-inset-bottom));

        background-color: #F5F5F5;
        position: fixed;
        left: 0;
        bottom: 0;
    }
}