.P-bindCardList {
    width: 100%;
    height: 100%;
    padding: 40px;
    background-color: #F5F5F5;

    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;

    display: flex;
    flex-direction: column; /* 竖向排布 */
    height: 100vh; /* 将容器高度设置为视口的100% */

    .list {
        width: 100%;
        margin-bottom: 60px;
        .list_type {
            font-size: 36px;
            font-family: Mont-Regular;
            color: #2C2C36;
            margin-bottom: 30px;
        }
        .list_s {
            width: 100%;
            .s_info {
                width: 100%;
                margin-bottom: 30px;
                .s_info_txt {
                    width: 100%;
                    padding: 30px 40px;
                    background-color: #fff;
                    border-radius: 6px;

                    font-size: 36px;
                    font-family: Mont-Regular;
                    color: #B8C0C9;
                    .name {
                        width: 100%;
                        display: flex;
                        justify-content: left;
                        align-items: center;
                        margin-bottom: 30px;

                        font-size: 36px;
                        font-family: Mont-Heavy;
                        color: #2C2C36;
                        img {
                            width: 60px;
                            height: 60px;
                            border-radius: 6px;
                            margin-right: 30px;
                        }
                    }
                }
                .limit_card, .error_card {
                    margin-top: 10px;
                    width: 100%;
                    padding: 20px;
                    background-color: #FDF6EA;
                    border-radius: 4px;
                    color: #DD783E;

                    font-size: 26px;
                    font-family: Mont-Bold;
                }
                .error_card {
                    background-color: #F72D57;
                    color: #fff;
                }
            }
            .s_add {
                width: 100%;
                padding: 40px;
                background-color: #007CFB;
                border-radius: 6px;

                font-size: 36px;
                font-family: Mont-Heavy;
                color: #fff;
                text-align: center;
            }
        }
    }
}